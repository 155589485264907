import { type FC, type MouseEventHandler, type ReactNode } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { SearchTag } from 'dibs-elements/exports/SearchTag';

import {
    isPriceFilter,
    isDimensionFilter,
    isFreeShippingFilter,
    constructStatsRange,
    isDeliveredByFilter,
    isLocalPickupFilter,
    isFramingFilter,
    isRingSizeFilter,
    isNetTradePriceDiscount,
    isNewArrivalsFilter,
    isSaleFilter,
} from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import { sbSharedRefineMenuMessages } from '../SbSharedRefineMenu/sbSharedRefineMenuMessages';
import { RING_SIZE_MM } from '../../constants/ringSize';
import { trackPillDeselectClick } from '../../utils/tracking/searchBrowse/filterTracking';
import { getPricePillValue } from '../SbSharedAppliedFilters/sbSharedAppliedFiltersValueHelper';

import styles from './SbMobileAppliedFiltersItem.scss';

type Props = {
    currency: string;
    filterName: string;
    onCloseClick: MouseEventHandler;
    availableValues: { displayName: string }[];
    appliedValue: {
        displayName: string;
        hexCode?: string;
        urlLabel?: string;
        properties?: {
            mm: string;
            us: string;
        };
    };
    titleMessage: string;
    measurementUnit?: string;
    ringMeasurementType?: string;
    localizedFilterValue?: string;
};

export const SbMobileAppliedFiltersItem: FC<Props> = ({
    appliedValue,
    filterName,
    availableValues,
    onCloseClick,
    currency,
    titleMessage,
    measurementUnit,
    ringMeasurementType,
}) => {
    const displayName = appliedValue?.displayName || null;
    const hexCode = appliedValue?.hexCode || null;
    const urlLabel = appliedValue?.urlLabel || null;
    let colorBubble = null;
    let title: ReactNode | string = titleMessage;
    let value: ReactNode | string = displayName;
    let dimension: ReactNode | null = null;

    if (isDimensionFilter(filterName)) {
        const [min, max] = constructStatsRange(urlLabel, availableValues?.[0]?.displayName);
        value = sbSharedRefineMenuMessages.minMaxFormattedMessage({ isMobile: true, min, max });
        if (measurementUnit) {
            dimension = <span className={styles.dimension}>{measurementUnit.toLowerCase()}</span>;
        }
    } else if (isPriceFilter(filterName)) {
        value = getPricePillValue({
            currency,
            displayName: appliedValue.displayName,
            isMobile: true,
        });
    } else if (isDeliveredByFilter(filterName)) {
        value = (
            <FormattedMessage
                id="sb.SbMobileAppliedFiltersItem.valueWithinDays"
                defaultMessage="Within {displayName} Days"
                values={{ displayName }}
            />
        );
    } else if (isLocalPickupFilter(filterName) || isNewArrivalsFilter(filterName)) {
        value = titleMessage;
    } else if (isFreeShippingFilter(filterName)) {
        title = sbSharedRefineMenuMessages.shippingOptions;
        value = sbSharedRefineMenuMessages.shippingFree;
    } else if (isFramingFilter(filterName)) {
        value = title;
    } else if (isRingSizeFilter(filterName)) {
        if (ringMeasurementType === RING_SIZE_MM) {
            value = appliedValue?.properties?.mm;
            dimension = (
                <FormattedMessage
                    id="sb.SbMobileAppliedFiltersItem.mmRingSize"
                    defaultMessage="mm"
                />
            );
        } else {
            value = appliedValue?.properties?.us;
        }

        value = (
            <FormattedMessage
                id="sb.SbMobileAppliedFiltersItem.ringSizeFilterPill"
                defaultMessage="Ring Size: {value}"
                values={{ value }}
            />
        );
    } else if (isNetTradePriceDiscount(filterName)) {
        value = (
            <FormattedMessage
                id="sb.SbMobileAppliedFiltersItem.netTradePriceDiscountFilterPill"
                defaultMessage="{value}%+ Net Price"
                values={{ value }}
            />
        );
    } else if (isSaleFilter(filterName)) {
        value = (
            <FormattedMessage
                id="sb.SbMobileAppliedFiltersItem.SaleFilterPill"
                defaultMessage="Sale"
            />
        );
    }

    if (hexCode) {
        colorBubble = <span className={styles.colorBubble} style={{ backgroundColor: hexCode }} />;
    }

    const handleDelete: MouseEventHandler = event => {
        onCloseClick(event);
        trackPillDeselectClick(filterName, urlLabel);
    };

    return (
        <div className={styles.filterItemWrapper}>
            <SearchTag dataTn={`filter-${filterName}`} onDelete={handleDelete}>
                {!!value && (
                    <div className={styles.filterItemContent} data-tn={`value-${filterName}`}>
                        {colorBubble}
                        {value || title}
                        {dimension}
                    </div>
                )}
            </SearchTag>
        </div>
    );
};
