import { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { VisibilityTracker } from 'dibs-visibility-tracker/exports/VisibilityTracker';

import { FormattedMessage } from 'dibs-react-intl';
import { Button } from 'dibs-elements/exports/Button';

import styles from './SbMobileRefineMenuHeader.scss';

export const SbMobileRefineMenuHeader = ({
    totalResultsCount,
    hideRefineMenu,
    hasFilterUpdates,
}) => {
    const topVisibilityRef = useRef(null);
    const [showStickyHeader, setShowStickyHeader] = useState(false);
    return (
        <Fragment>
            <div ref={topVisibilityRef} />
            <div
                className={classnames(styles.container, {
                    [styles.stickyHeader]: showStickyHeader,
                })}
            >
                <VisibilityTracker
                    elementRef={topVisibilityRef}
                    onVisibilityChange={({ isVisible }) => setShowStickyHeader(!isVisible)}
                    watchAfterFirstVisible
                />
                <div className={styles.headings}>
                    <div className={styles.heading}>
                        <FormattedMessage
                            id="sb.SbMobileRefineMenuHeader.heading"
                            defaultMessage="Filters"
                        />
                    </div>
                    <div className={styles.subheading} data-tn="total-results-count">
                        <FormattedMessage
                            id="sb.SbMobileRefineMenuHeader.subheading"
                            defaultMessage={`{totalResultsCount, plural, one {# result} other {# results}}`}
                            values={{ totalResultsCount }}
                        />
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <Button size="medium" fullWidth onClick={hideRefineMenu} dataTn="view-results">
                        {hasFilterUpdates ? (
                            <FormattedMessage
                                id="sb.SbMobileRefineMenuHeader.button.cta"
                                defaultMessage="Apply"
                            />
                        ) : (
                            <FormattedMessage
                                id="sb.SbMobileRefineMenuHeader.button.dismiss"
                                defaultMessage="Cancel"
                            />
                        )}
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

SbMobileRefineMenuHeader.propTypes = {
    totalResultsCount: PropTypes.number.isRequired,
    hideRefineMenu: PropTypes.func.isRequired,
    hasFilterUpdates: PropTypes.bool,
};
