/**
 * @generated SignedSource<<23ceabd44a97bfeb396f9bc425ebac90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbMobileRefineMenuSortBy_itemSearch$data = {
  readonly displayUriRef: string | null;
  readonly sort: {
    readonly currentOption: {
      readonly displayName: string | null;
      readonly urlLabel: string | null;
    } | null;
    readonly options: ReadonlyArray<{
      readonly displayName: string | null;
      readonly linkReference: string | null;
      readonly urlLabel: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "SbMobileRefineMenuSortBy_itemSearch";
};
export type SbMobileRefineMenuSortBy_itemSearch$key = {
  readonly " $data"?: SbMobileRefineMenuSortBy_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbMobileRefineMenuSortBy_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "urlLabel",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbMobileRefineMenuSortBy_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SortType",
      "kind": "LinkedField",
      "name": "sort",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseSortAvailableOptions",
          "kind": "LinkedField",
          "name": "options",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "linkReference",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseSortCurrentOption",
          "kind": "LinkedField",
          "name": "currentOption",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "0504100e285471b7dc00138e743c5ade";

export default node;
