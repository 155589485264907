import PropTypes from 'prop-types';
import style from './MobileModal.scss';
import CloseIcon from 'dibs-icons/exports/legacy/Close';
import { OverlayView } from '../../components/global/Overlay/OverlayView';
import { ModalContainer } from 'dibs-elements/exports/ModalContainer';
import classnames from 'classnames';

export function MobileModal(props) {
    const {
        usePortal = true,
        isOpen,
        handleCloseClick,
        children,
        hideCloseButton = false,
        handleScroll,
    } = props;
    const contentClassName = classnames(props.contentClassName);
    const overlayClass = classnames({
        'is-hidden': !isOpen,
    });

    const content = (
        <OverlayView
            hasPadding
            className={overlayClass}
            isBodyScrollLocked={isOpen}
            handleScroll={handleScroll}
        >
            {!hideCloseButton && (
                <button onClick={handleCloseClick} className={style.close} title="Close">
                    <CloseIcon className={style.closeIcon} />
                </button>
            )}
            <div className={contentClassName}>{children}</div>
        </OverlayView>
    );

    /**
     * Can't use the portal when you want to render the modal on the server. Why would you want to
     * do that? An example would be the refine menu on the search browse pages. It's a modal that
     * needs to be rendered on the server for SEO resons.
     */
    if (usePortal) {
        return <ModalContainer isOpen={isOpen}>{content}</ModalContainer>;
    } else {
        return content;
    }
}

MobileModal.propTypes = {
    handleCloseClick: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    usePortal: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
    contentClassName: PropTypes.string,
    className: PropTypes.string,
    handleScroll: PropTypes.func,
};
