import { graphql, createFragmentContainer } from 'react-relay/legacy';
import { useIntl, defineMessages } from 'dibs-react-intl';
import PropTypes from 'prop-types';

import { SbSharedRefineMenuFacet } from '../../SbSharedRefineMenu/SbSharedRefineMenuFacet/SbSharedRefineMenuFacet';
import { SbSharedRefineMenuRadioSelect } from '../../SbSharedRefineMenu/SbSharedRefineMenuRadioSelect/SbSharedRefineMenuRadioSelect';
import { SORT } from '../../SbSharedRefineMenu/sbSharedRefineMenuConstants';

export const messages = defineMessages({
    title: {
        id: 'sb.SbMobileRefineMenuSortByComponent.title',
        defaultMessage: 'Sort By',
    },
});

const SbMobileRefineMenuSortByComponent = ({ itemSearch }) => {
    const intl = useIntl();
    const { displayUriRef = '', sort = {} } = itemSearch;
    const currentOption = sort.currentOption || {};
    const sortOptions = sort.options || [];

    return (
        <SbSharedRefineMenuFacet
            storageFacetKey={SORT}
            title={intl.formatMessage(messages.title)}
            initiallyExpanded={false}
            trackingTrigger="sort by"
        >
            <SbSharedRefineMenuRadioSelect
                filterName={SORT}
                valuesToShow={sortOptions}
                displayUriRef={displayUriRef}
                canBeDismissed={false}
                appliedFilterValues={[currentOption]}
            />
        </SbSharedRefineMenuFacet>
    );
};

SbMobileRefineMenuSortByComponent.propTypes = {
    itemSearch: PropTypes.shape({
        sort: PropTypes.object,
        displayUriRef: PropTypes.string,
    }),
};

export const SbMobileRefineMenuSortBy = createFragmentContainer(SbMobileRefineMenuSortByComponent, {
    itemSearch: graphql`
        fragment SbMobileRefineMenuSortBy_itemSearch on ItemSearchQueryConnection {
            displayUriRef
            sort {
                options {
                    urlLabel
                    linkReference
                    displayName
                }
                currentOption {
                    urlLabel
                    displayName
                }
            }
        }
    `,
});
