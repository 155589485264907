import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type FilterProps = {
    className?: string;
};

const Filter: FunctionComponent<FilterProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'filter'}
            viewBox="0 0 250 250"
        >
            <polygon points="232.2,15 232.2,61 -0.2,61 -0.2,79 232.2,79 232.2,125 250.2,125 250.2,15" />
            <polygon points="249.8,175 17.9,175 17.9,125 -0.1,125 -0.1,175 -0.2,175 -0.2,193 -0.1,193 -0.1,235 17.9,235 17.9,193 249.8,193" />
        </svg>
    );
};
export default Filter;
