import { defineMessages } from 'dibs-react-intl';

export const SbMobileSortRefineMessages = defineMessages({
    refineButton: {
        id: 'sb.SbMobileSortRefine.refineButton',
        defaultMessage: 'Refine',
    },
    sortButton: {
        id: 'sb.SbMobileSortRefine.sortButton',
        defaultMessage: 'Sort',
    },
    sortAndRefine: {
        id: 'sb.SbMobileSortRefine.sortAndRefine',
        defaultMessage: 'Filters',
    },
    totalResults: {
        id: 'sb.SbMobileSortRefine.totalResults',
        defaultMessage: '{resultCount, plural, one {# result} other {# results}}',
    },
});
