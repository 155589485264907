import { graphql, createFragmentContainer } from 'react-relay/legacy';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'dibs-react-intl';

import { SbMobileAppliedFiltersItem } from './SbMobileAppliedFiltersItem';
import { useMeasurementUnit } from '../useMeasurementUnit';
import { useRingMeasurementType } from '../hooks/ringSize/useRingMeasurementType';
import { isBatchAppliedFilter } from '../SbMobileRefineMenu/SbMobileRefineMenuFilters/sbMobileRefineMenuFiltersMap';

import styles from './SbMobileAppliedFilters.scss';
import {
    closeAppliedFilterHandler,
    clearAllAppliedFiltersHandler,
    getAppliedFilterValues,
    getValidSharedAppliedFilters,
} from '../SbSharedAppliedFilters/sbSharedAppliedFiltersHelpers';
import { Link } from 'dibs-elements/exports/Link';

export const SbMobileAppliedFiltersComponent = ({
    currency,
    itemSearch,
    user,
    onCloseClick = () => {},
    onClearAllClick = () => {},
    generatedUriRef,
}) => {
    const { appliedFilters, filters } = itemSearch;
    const validAppliedFilters = getValidSharedAppliedFilters(appliedFilters);
    const [measurementUnit] = useMeasurementUnit({ user, itemSearch });
    const [ringMeasurementType] = useRingMeasurementType({ currency, itemSearch });
    const validAppliedFilterComponents = validAppliedFilters.map(appliedFilter => {
        const availableFilter = filters.find(filter => filter.name === appliedFilter.name);
        const availableFilterValues = availableFilter?.values || [];
        const isBatchApplied = isBatchAppliedFilter(appliedFilter.name);
        const values = getAppliedFilterValues({ appliedFilter, isBatchApplied });

        return values.map((filterValue, index) => {
            return (
                <SbMobileAppliedFiltersItem
                    key={`${appliedFilter.name}-${index}`}
                    currency={currency}
                    filterName={appliedFilter.name}
                    appliedValue={!isBatchApplied ? filterValue : null}
                    availableValues={availableFilterValues}
                    titleMessage={appliedFilter.localizedFilterName}
                    onCloseClick={event =>
                        onCloseClick({
                            filterName: appliedFilter.name,
                            filterValue,
                            generatedUriRef,
                            isBatchApplied,
                            event,
                        })
                    }
                    measurementUnit={measurementUnit}
                    ringMeasurementType={ringMeasurementType}
                    localizedFilterValue={appliedFilter.localizedFilterValue}
                />
            );
        });
    });

    if (validAppliedFilters.length === 0) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.inner}>
                <>
                    {validAppliedFilterComponents}
                    <Link
                        className={styles.clearAll}
                        onClick={event => {
                            onClearAllClick({ event });
                        }}
                        dataTn="applied-filters-clear-all"
                    >
                        <FormattedMessage
                            id="sb.SbMobileAppliedFilters.clearAll"
                            defaultMessage="Clear All"
                        />
                    </Link>
                </>
            </div>
        </div>
    );
};

SbMobileAppliedFiltersComponent.propTypes = {
    user: PropTypes.object,
    itemSearch: PropTypes.shape({
        clearAppliedFiltersLinkReference: PropTypes.string,
        appliedFilters: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                values: PropTypes.arrayOf(
                    PropTypes.shape({
                        displayName: PropTypes.string.isRequired,
                        urlLabel: PropTypes.string.isRequired,
                        linkReference: PropTypes.string.isRequired,
                        hexCode: PropTypes.string,
                    })
                ).isRequired,
            })
        ).isRequired,
        filters: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                values: PropTypes.arrayOf(
                    PropTypes.shape({
                        displayName: PropTypes.string.isRequired,
                        urlLabel: PropTypes.string.isRequired,
                        linkReference: PropTypes.string.isRequired,
                        properties: PropTypes.shape({
                            mm: PropTypes.string,
                            us: PropTypes.string,
                        }),
                    })
                ).isRequired,
            })
        ).isRequired,
    }),
    currency: PropTypes.string.isRequired,
    // redux
    onCloseClick: PropTypes.func.isRequired,
    onClearAllClick: PropTypes.func.isRequired,
    generatedUriRef: PropTypes.string,
};

function mapDispatchToProps(dispatch, props) {
    return {
        onCloseClick: closeAppliedFilterHandler(dispatch),
        onClearAllClick: clearAllAppliedFiltersHandler(dispatch, props),
    };
}

function mapStateToProps(state) {
    return {
        generatedUriRef: state?.filters?.generatedUriRef,
    };
}

export const SbMobileAppliedFilters = createFragmentContainer(
    connect(mapStateToProps, mapDispatchToProps)(SbMobileAppliedFiltersComponent),
    {
        itemSearch: graphql`
            fragment SbMobileAppliedFilters_itemSearch on ItemSearchQueryConnection {
                ...useMeasurementUnit_itemSearch
                ...useRingMeasurementType_itemSearch
                clearAppliedFiltersLinkReference
                filters {
                    name
                    localizedFilterName
                    values {
                        displayName
                        urlLabel
                        linkReference
                        properties {
                            mm
                            us
                        }
                    }
                }
                appliedFilters {
                    name
                    localizedFilterName
                    canBeDismissed
                    values {
                        urlLabel
                        linkReference
                        displayName
                        hexCode
                        properties {
                            us
                            mm
                        }
                    }
                }
            }
        `,
        user: graphql`
            fragment SbMobileAppliedFilters_user on User {
                ...useMeasurementUnit_user
            }
        `,
    }
);
